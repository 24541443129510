import React, { useState } from "react";
import styles from "./TaskEmailModal.module.scss";
import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import Loader from "../../Loader/Loader";

export const TaskEmailModal = ({ onClose, handleSubmit, isLoading }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ show: false, text: "" });

  const showMessageHandler = (text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  };

  const _handleSubmit = async () => {
    try {
      const response = await handleSubmit(email);

      if (response.emailVerified) {
        setEmail("");
        onClose();
      } else {
        showMessageHandler("Email not found in the system");
      }
    } catch (error) {
      showMessageHandler("An error occurred while checking email");
    }
  };

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.emailModal}
      backgroundClassName={styles.backgroundWrapper}
      closeButtonClassName={styles.closeButton}
      header={<br></br>}
    >
      <Text variant="textXL" weight="bold" className={styles.title}>
        Please, enter the email that you used in the process of app
        installation.
      </Text>

      <div className={styles.content}>
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setMessage({ show: false, text: "" });
          }}
          placeholder="Enter your email"
          className={styles.emailInput}
        />
        <Button
          variant="filledYell"
          size="sizeM"
          fullWidth
          onClick={_handleSubmit}
          disabled={!email || isLoading}
        >
          {isLoading ? (
            <Loader isDark />
          ) : (
            <Text variant="textL" weight="semiBold">
              Confirm
            </Text>
          )}
        </Button>
      </div>

      {message.show && (
        <div className={styles.popupMessage}>
          <Text variant="textXM" weight="bold">
            {message.text}
          </Text>
        </div>
      )}
    </ModalWrapper>
  );
};

export const TaskEmailModalContext = React.createContext(null);

export const TaskEmailModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const handleModalState = (val, data = null) => {
    setIsOpen(val);
    setModalData(data);
  };

  return (
    <TaskEmailModalContext.Provider
      value={{
        isOpen,
        modalData,
        setIsOpen: handleModalState,
      }}
    >
      {children}
      {isOpen && modalData && (
        <TaskEmailModal
          onClose={() => handleModalState(false)}
          handleSubmit={(email) => modalData.handleSubmit(email)}
          isLoading={modalData.isLoading}
        />
      )}
    </TaskEmailModalContext.Provider>
  );
};

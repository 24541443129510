import customStyles from './VerifyEmail.module.scss'
import {animated, config, useTransition} from "@react-spring/web";
import * as Dialog from "@radix-ui/react-dialog";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import {useEffect, useState} from "react";
import useApi from "../../../api/rest";
import {DateTime, Duration} from "luxon";
import clsx from "clsx";
import Loader from "../../Loader/Loader";
import {useApp} from "../../../context/AppProvider";

const TEN_MINUTES_IN_MILLISECONDS = 600000

const calculateTimeDiff = (time) => {
  const timePlusTen = DateTime.fromISO(time).plus(TEN_MINUTES_IN_MILLISECONDS)
  const diff = timePlusTen.diff(DateTime.utc()).milliseconds
  if (diff > 0) {
    return diff
  } else {
    return undefined
  }
}

const VerifyEmail = ({showModal, setShowModal, userData, taskData}) => {
  const { getUserInfo, verifyEmail, checkTask } = useApi()
  const { userInfo, setUserInfo } = useApp()
  const [valueEmail, setValueEmail] = useState(userData.sentEmail || userData.email || '')
  const [invalidEmailError, setInvalidEmailError] = useState(null)
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [isEmailSaved, setIsEmailSaved] = useState(userData.sentEmail && calculateTimeDiff(userData.sentEmailCreatedAtFormatted) !== undefined)
  const [resendEmailTimeLeft, setResendEmailTimeLeft] = useState(userData.sentEmail && calculateTimeDiff(userData.sentEmailCreatedAtFormatted) !== undefined ? calculateTimeDiff(userData.sentEmailCreatedAtFormatted) : null)

  const [valueCode, setValueCode] = useState('')
  const [invalidCodeError, setInvalidCodeError] = useState(null)
  const [isSubmittingCode, setIsSubmittingCode] = useState(false)

  const handleSuccessClose = () => {
    setShowModal(false)
    setValueEmail('')
    setInvalidEmailError(null)
    setIsSubmittingEmail(false)
    setIsEmailSaved(false)
    setResendEmailTimeLeft(null)
    setValueCode('')
    setInvalidCodeError(null)
    setIsSubmittingCode(false)
  }

  const handleEmailInputChange = (e) => {
    setInvalidEmailError(null)
    setValueEmail(e.target.value)
  }

  const handleCodeInputChange = (e) => {
    if (/^\d{0,7}$/.test(e.target.value)) {
      setInvalidCodeError(null)
      setValueCode(e.target.value)
    } else {
      e.preventDefault()
    }
  }

  const handleSubmitEmail = (value) => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

    if (!isValidEmail) {
      setInvalidEmailError('Invalid email')
    } else {
      setInvalidEmailError(null)
      setIsSubmittingEmail(true)
      verifyEmail(value).then((result) => {
        if (result.status === 'ok') {
          getUserInfo().then((data) => {
            if (data.status === 'error') {
              // TODO add error handling
            } else {
              setUserInfo(data)
              if (data?.sentEmail) {
                setValueEmail(data?.sentEmail)
                setIsEmailSaved(true)
                setResendEmailTimeLeft(data.sentEmailCreatedAtFormatted ? calculateTimeDiff(data.sentEmailCreatedAtFormatted) : TEN_MINUTES_IN_MILLISECONDS)
                setIsSubmittingEmail(false)
              }
            }
          })
        }
        if (result.status === 'error') {
          setInvalidEmailError(result.reason)
          setIsSubmittingEmail(false)
          setIsEmailSaved(false)
          setResendEmailTimeLeft(TEN_MINUTES_IN_MILLISECONDS)
        }
      })
    }
  }

  const handleSubmitCode = (code) => {
    const isCodeValid = /^\d{7}$/.test(code)

    if (!isCodeValid) {
      setInvalidCodeError('Invalid code, code should contain 7 digits')
    } else {
      setIsSubmittingCode(true)
      const taskDataForReq = {
        code: code,
        email: valueEmail
      }
      checkTask(taskData.taskId, taskDataForReq).then((result) => {
        if (result.status === 'error') {
          setInvalidCodeError(result.reason)
          setIsSubmittingCode(false)
        }
        if (result.status !== 'error') {
          setTimeout(() => {
            const updatedTasks = userInfo.tasks
            updatedTasks[taskData.taskId] = result

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks
            })
            handleSuccessClose()
          }, 1000)
        }
      })
    }
  }

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  }

  const transitions = useTransition(showModal, {
    from: {opacity: 0, y: 1000},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: 1000},
    config: config.default,
  })

  useEffect(() => {
    let timerId;
    if (resendEmailTimeLeft !== null && resendEmailTimeLeft > 0) {
      timerId = setInterval(() => {
        setResendEmailTimeLeft(resendEmailTimeLeft - 1000)
      }, 1000)
    }
    if (resendEmailTimeLeft <= 0) {
      setResendEmailTimeLeft(null)
      setIsEmailSaved(false)
      setIsSubmittingEmail(false)
      setInvalidEmailError(null)
      setInvalidCodeError(null)
    }

    return () => {
      clearInterval(timerId)
    }
  }, [resendEmailTimeLeft])

  return (
    <Dialog.Root open={showModal} onOpenChange={setShowModal}>
      {transitions((styles, item) => {
        return item ? (
          <>
            <Dialog.Overlay forceMount asChild>
              <animated.div
                className={customStyles.overlay}
                style={{
                  opacity: styles.opacity,
                }}
              >
                <>
                  <Dialog.Content forceMount asChild>
                    <animated.div style={styles} className={customStyles.content}>
                      <div className={customStyles.heading}>
                        <Text variant="textXL" weight="semiBold" color='white'>
                          Verify your e-mail
                        </Text>
                        <Text variant="textXM" color="white50">
                          Please enter and verify the email you used to sign up for the Drum App.
                        </Text>
                        <Text variant="textXM" color="white50">
                          It usually takes just a few minutes. Can’t find the verification email? Check your spam folder. Your code is valid for 10 minutes.
                        </Text>
                      </div>
                      <div className={customStyles.form}>
                        <div className={clsx(customStyles.formField)}>
                          <label htmlFor="email" className={customStyles.label}>
                            <Text variant="textXM">Email</Text>
                          </label>
                          <div className={customStyles.inputFiled}>
                            <input className={clsx(customStyles.input, invalidEmailError !== null ? customStyles.error : '')}
                                   id="email"
                                   value={valueEmail}
                                   type="email"
                                   onChange={handleEmailInputChange}
                                   placeholder="Enter your email"
                                   enterKeyHint="done"
                                   onKeyUp={onEnter}
                                   disabled={isSubmittingEmail || isEmailSaved}
                            />
                            <div className={customStyles.rightBtnContainer}>
                              { resendEmailTimeLeft ?
                                <Text variant="textM" color="white50" weight="semiBold" align="left">
                                  {Duration.fromMillis(resendEmailTimeLeft).toFormat("mm:ss")}
                                </Text>
                                :
                                isSubmittingEmail ?
                                  <Loader disabled/>
                                  :
                                  <Button variant="transparent" onClick={() => handleSubmitEmail(valueEmail)}>
                                    <Text variant="textM" weight="semiBold" color="primary">
                                      Send
                                    </Text>
                                  </Button>
                              }
                            </div>
                          </div>
                          {invalidEmailError &&
                          <div className={customStyles.errorContainer}>
                            <Text variant="textXM" color="error">
                              {invalidEmailError}
                            </Text>
                          </div>
                          }
                        </div>
                        <div className={customStyles.formField}>
                          <label htmlFor="code" className={customStyles.label}>
                            <Text variant="textXM">Verification code from email</Text>
                          </label>
                          <div className={customStyles.inputFiled}>
                            <input className={clsx(customStyles.input, invalidCodeError ? customStyles.error : '')}
                                   id="code" type="text"
                                   value={valueCode}
                                   onChange={handleCodeInputChange}
                                   placeholder="Enter code"
                                   enterKeyHint="done"
                                   onKeyUp={onEnter}
                                   disabled={!isEmailSaved}
                            />
                            <div className={customStyles.rightBtnContainer}>
                              { isEmailSaved && isSubmittingCode && !invalidCodeError ?
                                <Loader disabled/>
                                : null
                              }
                              { isEmailSaved && !isSubmittingCode && !invalidCodeError ?
                                <Button variant="transparent" onClick={() => handleSubmitCode(valueCode)} disabled={invalidCodeError !== null}>
                                  <Text variant="textM" weight="semiBold" color="primary">
                                    Verify
                                  </Text>
                                </Button>
                                : null
                              }
                            </div>
                          </div>
                          <div className={customStyles.errorContainer}>
                            { invalidCodeError &&
                              <Text variant="textXM" color="error" >
                                {invalidCodeError}
                              </Text>
                            }
                          </div>
                        </div>
                      </div>
                    </animated.div>
                  </Dialog.Content>
                </>
              </animated.div>
            </Dialog.Overlay>
          </>
        ) : null
      })}
    </Dialog.Root>
  )
}

export default VerifyEmail
import React, { useState } from "react";
import styles from "../Tasks.module.scss";
import clsx from "clsx";
import Text from "../../../base/Text/Text";
import Icon from "../../../Icon/Icon";
import { formatNumberForUi } from "../../../../utils/strings";
import { TOKEN_NAME } from "../../../../constants";
import Button from "../../../base/Button/Button";
import Loader from "../../../Loader/Loader";
import TaskDescriptionModal from "../../../Modals/DisclaimerModal/TaskDescriptionModal";

import { TaskEmailModalContext } from "../../../Modals/TaskEmailModal/TaskEmailModal";

const Task = ({
  task,
  handleStartTask,
  startTaskLoading,
  handleGoTask,
  handleClaim,
  claimTaskLoading,
  handleCheckTask,
  checkTaskLoading,
}) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const { setIsOpen } = React.useContext(TaskEmailModalContext);

  if (!task || !task.taskData) {
    return null;
  }

  const isMaxWatchesReached =
    task.taskData.type === "repeatable" &&
    task.taskData.taskDetails?.maxDaily &&
    task.taskData.watchedCount >= task.taskData.taskDetails.maxDaily;

  const _handleStartTask = () => {
    if (
      task.taskData.taskDetails?.tooltipDescription &&
      task.taskData.state !== "Claimed"
    ) {
      setShowDescriptionModal(true);
    }
    handleStartTask?.(task);
  };

  const _handleGoTask = () => {
    if (task.taskData.checkDetails?.type === "requestToApi") {
      setIsOpen(true, {
        handleSubmit: (email) => handleGoTask(task.taskData, { email }),
        isLoading: checkTaskLoading === task.taskId,
      });
    } else {
      if (
        task.taskData.taskDetails?.tooltipDescription &&
        task.taskData.state !== "Claimed"
      ) {
        setShowDescriptionModal(true);
      }
      handleGoTask?.(task.taskData);
    }
  };
  const _handleCheckTask = () => {
    if (task.taskId === "install_wigwam_app") {
      setIsOpen(true, {
        handleSubmit: async (email) => {
          if (!email) {
            return;
          }
          return await handleCheckTask(task.taskId, { email });
        },
        isLoading: checkTaskLoading === task.taskId,
      });
    } else {
      handleCheckTask(task.taskId);
    }
  };

  return (
    <>
      <li key={task.taskId} className={styles.task}>
        <div className={styles.descriptionContainer}>
          <div
            className={clsx(
              styles.description,
              task.taskData.state === "Claimed" ? styles.claimed : "",
              isMaxWatchesReached ? styles.claimed : "",
            )}
          >
            <div className={styles.title}>
              <Text
                variant="textM"
                weight="semiBold"
                align="left"
                className={styles.content}
              >
                {task.taskData.title}{" "}
                {task.taskData.taskDetails?.description
                  ? `(${task.taskData.taskDetails.description})`
                  : ""}
                {task.taskData.taskDetails?.tooltipDescription &&
                  task.taskData.state !== "Claimed" && (
                    <Button
                      className={styles.descButton}
                      variant="icon"
                      size="size0"
                      onClick={() => setShowDescriptionModal(true)}
                    >
                      <Icon name="info" width="16px" height="16px" />
                    </Button>
                  )}
              </Text>
            </div>
            <div className={styles.reward}>
              <Text variant="h3" align="left" weight="bold">
                +{formatNumberForUi(task.taskData.reward)}{" "}
                <Text variant="h3" align="left" weight="bold" color="gradient">
                  {TOKEN_NAME}
                </Text>
              </Text>
            </div>
          </div>
          {task.taskData.state === "NONE" && (
            <Button
              variant="filledThird"
              size="sizeM"
              onClick={_handleStartTask}
              disabled={startTaskLoading === task.taskId || isMaxWatchesReached}
            >
              {startTaskLoading === task.taskId && <Loader />}
              <Text variant="textL" weight="semiBold">
                {isMaxWatchesReached ? "Wait to the next day" : "Start"}
              </Text>
            </Button>
          )}
          {task.taskData.state === "Claimed" && (
            <Icon name="success-small" width="32px" height="32px" />
          )}
        </div>
        {task.taskData.state !== "NONE" &&
          task.taskData.state !== "Claimed" && (
            <div className={styles.actionsContainer}>
              <Button
                variant="outlined"
                size="sizeM"
                fullWidth
                onClick={_handleGoTask}
                disabled={isMaxWatchesReached}
              >
                <Text variant="textL" weight="semiBold">
                  {isMaxWatchesReached ? "Wait to the next day" : "Go"}
                </Text>
              </Button>
              {(task.taskData.state === "ReadyToClaim" ||
                task.taskData.state === "Started") && (
                <Button
                  variant="filledThird"
                  size="sizeM"
                  fullWidth
                  onClick={() => handleClaim(task.taskId)}
                  disabled={
                    task.taskData.state !== "ReadyToClaim" ||
                    claimTaskLoading === task.taskId ||
                    isMaxWatchesReached
                  }
                >
                  {claimTaskLoading === task.taskId && <Loader />}
                  <Text variant="textL" weight="semiBold">
                    Claim
                  </Text>
                </Button>
              )}
              {task.taskData.state === "ReadyToCheck" && (
                <Button
                  className={styles.checkBtn}
                  variant="filledYell"
                  size="sizeM"
                  fullWidth
                  onClick={_handleCheckTask}
                  disabled={
                    checkTaskLoading === task.taskId || isMaxWatchesReached
                  }
                >
                  {checkTaskLoading === task.taskId && <Loader isDark />}
                  <Text variant="textL" weight="semiBold">
                    Check
                  </Text>
                </Button>
              )}
            </div>
          )}
        {task.taskData.state === "Claimed" &&
          task.taskData.type !== "referral" && (
            <div className={styles.actionsContainer}>
              <Button
                variant="outlined"
                size="sizeM"
                fullWidth
                onClick={_handleGoTask}
              >
                <Text variant="textL" weight="semiBold">
                  Go
                </Text>
              </Button>
            </div>
          )}
      </li>

      <TaskDescriptionModal
        showModal={showDescriptionModal}
        setShowModal={setShowDescriptionModal}
        data={task.taskData.taskDetails?.tooltipDescription}
      />
    </>
  );
};

export default Task;
